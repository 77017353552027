<template>
  <v-footer
    color="transparent"
    height="auto"
    class="px-5 mb-3"
    light
  >
    <v-layout
      wrap
      justify-center
    >
      <v-btn
        v-for="(link, i) in links"
        :key="`link-${i}`"
        :to="link[0]"
        flat
        small
        class="mx-auto"
      >
        {{ link[1] }}
      </v-btn>
      <v-spacer />
      <v-flex
        xs12
        hidden-md-and-up
      />
      <v-btn
        v-for="(social, i) in socials"
        :key="`social-${i}`"
        :href="social[0]"
        target="_blank"
        rel="noopener"
        class="px-0"
        color="grey darken-1"
        dark
        tile
        style="min-width: 48px;"
      >
        <v-icon
          size="18"
          v-text="social[1]"
        />
      </v-btn>
    </v-layout>
  </v-footer>
</template>

<script>
  export default {
    name: 'Footer',

    data: () => ({
      links: [
        ['/', 'Home'],
        ['/projects', 'Projects'],
        ['/team', 'Team'],
        ['/about', 'About']
      ],
      socials: [
        ['https://facebook.com', 'mdi-instagram'],
        ['https://twitter.com', 'mdi-twitter']
      ]
    }),

    computed: {
      categories () {
        return []
      }
    }
  }
</script>
